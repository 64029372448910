import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faVk,
	faYoutube,
	faTelegram
} from '@fortawesome/free-brands-svg-icons';

/**
 * Container element
 */
const Container = styled.footer`
  background: #f1f1f1;
  color: #949494;
  font-family: "Open Sans", sans-serif;
  font-size: 0.87rem;
`;

/**
 * Content element
 */
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: .5rem;
  box-sizing: border-box;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
`;

/**
 * Social link list element
 */
const SocialLinkList = styled.div`
  margin-bottom: 20px;
  cursor: default;
`;

/**
 * Social link element
 */
const SocialLink = styled.a`
  font-size: 24px;
  color: #949494;
  margin: 0 5px;
  transition: .25s ease-in-out;
  
  
  &:hover {
    color: ${props => props.hoverColor}
  }
`;

/**
 * Component
 *
 * @param year
 * @param withSocialLinks
 * @return Element
 * @constructor
 */
export default function Light ({ year, withSocialLinks, text}) {
	return (
		<Container>
			<Content>
				{withSocialLinks && (
					<SocialLinkList>
						<SocialLink hoverColor="#4267b2" href="#">
							<FontAwesomeIcon icon={faFacebookF} />
						</SocialLink>
						<SocialLink hoverColor="#1da1f2" href="#">
							<FontAwesomeIcon icon={faTwitter} />
						</SocialLink>
						<SocialLink hoverColor="#ed7332" href="#">
							<FontAwesomeIcon icon={faInstagram} />
						</SocialLink>
						<SocialLink hoverColor="#5181b8" href="#">
							<FontAwesomeIcon icon={faVk} />
						</SocialLink>
						<SocialLink hoverColor="#ff0621" href="#">
							<FontAwesomeIcon icon={faYoutube} />
						</SocialLink>
						<SocialLink hoverColor="#2ca5de" href="#">
							<FontAwesomeIcon icon={faTelegram} />
						</SocialLink>
					</SocialLinkList>
				)}
				<p>{text.replace('{year}', year)}</p>
			</Content>
		</Container>
	);
}

Light.propTypes = {
	withSocialLinks: PropTypes.bool,
	year: PropTypes.number,
	text: PropTypes.string
};

Light.defaultProps = {
	withSocialLinks: true,
	year: (new Date()).getFullYear(),
	text: `Copyright © ${(new Date()).getFullYear()}. Все права защищены.`
};
