import axios from 'axios';
import env from '../utils/env';

/**
 * Client factory
 *
 * @param config
 * @return {AxiosInstance}
 */
export function axiosFactory (config = {}) {
    return axios.create(config);
}

/**
 * Create token on server request
 *
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createTokenRequest = token => {
    const formData = new FormData;
    formData.append('token', token);

    return axiosFactory().post(env('REACT_APP_PUSH_RECEIVER_URI'), formData);
}