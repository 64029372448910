/**
 * Get variable from .env
 *
 * @param key
 * @param _default
 * @return {string|*}
 */
export default function (key, _default = null) {
	return key in process.env ? process.env[key] : _default;
};
