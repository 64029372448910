import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Styled item element
 */
const Item = styled.div`
  --height: 16.5rem;
  height: var(--height);
  box-sizing: border-box;
  padding: 0.3rem;
  
  ${media.lessThan('medium')`
  	min-height: var(--height);
  	height: auto;
  	width: 100%;
  `}
`;

/**
 * Small item element
 */
const Small = styled(Item)`
  width: 25%;
`;

/**
 * Large item element
 */
const Large = styled(Item)`
  width: 50%;
`;

export default {
	Large,
	Small
};
