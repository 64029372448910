import VirtualGridElement from "./VirtualGridElement.js";
import VirtualGridRow from "./VirtualGridRow.js";

export default class VirtualGrid {
    #rows = [];
    #elements = [];
    #columns = null;

    constructor(columns){
        this.#columns = parseInt(columns, 10);
        if (this.#columns < 1) {
            throw new Error("Incorrect column size");
        }
    }

    #getAppropriateColumnByRows = function(columns, rows, width){
        loop1 :
        for (let column of columns) {
            let isColumnAppropriate = true;

            loop2 :
            for (let row of rows) {
                if (!row.hasSpaceAfterColumn(width, column)) {
                    isColumnAppropriate = false
                    break loop2;
                }
            }

            if (isColumnAppropriate) {
                return column;
            }
        }

        return null;
    }

    #getFreeCoordinates = function(width, height){
        let needRows = height;
        let rowStart = null;
        let columnStart = null;

        for (let row of this.#rows) {
            if (row.hasSpace(width)) {

                if (columnStart === null) {
                    let freeColumns = row.getFreeColumnsForWidth(width); //!!
                    let appropriateRows = this.#rows.filter(r => r.number >= row.number && r.number <= (row.number + height - 1));

                    if (appropriateRows.length < height) {
                        let needRows = height - appropriateRows.length;
                        for (let i = 0; i < needRows; i++) {
                            let maxRowNumber = this.#rows.length;
                            let row_ = new VirtualGridRow(maxRowNumber + 1, this.#columns);
                            this.#rows.push(row_);
                            appropriateRows.push(row_);
                        }
                    }

                    columnStart = this.#getAppropriateColumnByRows(freeColumns, appropriateRows, width);
                    
                    if (columnStart !== null) {
                        rowStart = row.number;
                    }
                } else if (!row.hasSpaceAfterColumn(width, columnStart)) {
                    rowStart = null;
                    columnStart = null;
                    needRows = height;
                    continue;
                } else {
                    needRows -= 1;
                }

                if (rowStart === null) {
                    rowStart = row.number;
                }

            } else {
                rowStart = null;
                columnStart = null;
                needRows = height;
            }

            if (!needRows) {
                break;
            }
        }

        if (needRows) {
            if (columnStart === null) {
                columnStart = 1;
            }

            while (needRows) {
                let maxRowNumber = this.#rows.length;
                let row = new VirtualGridRow(maxRowNumber + 1, this.#columns);
                
                this.#rows.push(row);
                
                if (rowStart === null) {
                    rowStart = row.number;
                }

                needRows--;
            }
        }

        return { rowStart, columnStart };
    }

    appendElement(width, height, element = null){
        if (width > this.#columns) {
            throw new Error(`Max element width must be no then ${this.#columns}`);
        }
        if (!(element instanceof Object)) {
            throw new Error(`Element must be an instance of Object`);
        }
        if (this.#elements.find(el => el === element)) {
            throw new Error(`Element already exists in grind`);
        }

        const { rowStart, columnStart } = this.#getFreeCoordinates(width, height);
        const rowEnd = rowStart + height;
        const columnEnd = columnStart + width;
        
        const virtualGridElement = (new VirtualGridElement(rowStart, rowEnd, columnStart, columnEnd, element));

        let currentRow = rowStart;

        for (let i = 1; i <= height; i++) {
            let row = this.#rows.find(row => row.number === currentRow);
            
            let currentColumn = columnStart;
            for (let j = 1; j <= width; j++) {
                row.appendElementToColumn(virtualGridElement, currentColumn);
                currentColumn++;
            }
            currentRow++;
        }

        this.#elements.push(virtualGridElement);
    }

    getElementCoordinates(element){
        const virtualGridElement = this.#elements.find(el => el.element === element);

        return virtualGridElement ? virtualGridElement.coordinates : null;
    }
}
