import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';

/**
 * Container element
 */
const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.img`
    transition: 0.5s;
    height: 190px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-size: cover;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    flex-direction: column;
    color: white;
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    text-align: center;
    ${Container}:hover & {
        filter: brightness(0.6);
    }
`;

/**
 * Content element
 */
const Content = styled.div`
    color: #656565;
    font-size: 14px;
    line-height: 18px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: normal;
    padding: 15px;
    background: #ffffffcf;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    height: calc(100% - 190px);
`;

/**
 * ContentFooter element
 */
const ContentFooter = styled.div`
    padding-top: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @return Element
 * @constructor
 */
export default function LightSmall ({ thumbnail, text, link }) {
	return (
		<Container onClick={() => window.location.replace(link)}>
			<Thumbnail src={thumbnail} alt={text}/>
			<Content>
				<span>{text}</span>
				<ContentFooter>
					<FontAwesomeIcon icon={faCommentAlt} />
				</ContentFooter>
			</Content>
		</Container>
	);
}

LightSmall.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};
