import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.div`
  height: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 
  	'Open Sans', 
  	-apple-system, 
  	BlinkMacSystemFont, 
  	"Segoe UI", 
  	Roboto, 
  	"Helvetica Neue", 
  	Arial, 
  	"Noto Sans", 
  	sans-serif, 
  	"Apple Color Emoji", 
  	"Segoe UI Emoji", 
  	"Segoe UI Symbol", 
  	"Noto Color Emoji";
  transition: .2s;
  &:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  border: 1px solid lightgray;
  ${media.lessThan('large')`
  	max-width: 300px;
	  margin: 0 auto;
  `}
`;

/**
 * Content element
 */
const Content = styled.div`
  margin: 0rem 1rem;
  height: 30%;
  display: flex;
  align-items: center;
  ${media.lessThan('large')`
	margin: .3rem .5rem;
  `}
`;

const Image = styled.img`
  height: 70%;
  ${media.lessThan('large')`
  `}
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

/**
 * Text element
 */
const Text = styled.p`
  font-weight: bold;
  color: darkslategray;
  font-size: 14px;
  ${media.lessThan('large')`
  	margin: 0;
  `}
`;

/**
 * Small tile element
 *
 * @param thumbnail
 * @param text
 * @param link
 * @constructor
 */
export default function Small ({ thumbnail, text, link, image}) {
	return (
		<Container onClick={() => window.open(link)}>
			<Image src={thumbnail} alt={text}/>
			<Content>
				<Text>{text}</Text>
			</Content>
		</Container>
	);
};

Small.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};
