import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  ${media.lessThan('medium')`
    margin-left: .3rem;
    margin-right: .3rem;
  `}
`;

export default Container;
