import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faGooglePlusSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import media from 'styled-media-query';
import logoEs from './logo.png';

/**
 * Container element
 */
const Container = styled.header`
  height: 65px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  background: #9a4e36;
  z-index: 20;
`;

/**
 * Content element
 */
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

/**
 * Logotype element
 */
const Logotype = styled.div``;

/**
 * Logotype link element
 */
const LogotypeLink = styled.a`
  font-family: monospace;
  font-size: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 20px;
  color: white;
  border-radius: 4px;
`;

/**
 * Logotype label element
 */
const LogotypeLabel = styled.span`
  margin-left: 2px;
  padding: 3px;
  color: white;
  background-color: #a08066;
  box-shadow: 1px 1px 3px #b5b1b1;
  border-radius: 5px;
`;

/**
 * Navigation element
 */
const Navigation = styled.nav`
  ${media.lessThan('medium')`
  margin-right: 20px;
  `}
`;

/**
 * Menu element
 */
const Menu = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  height: 30px;
  align-items: center;
  margin-right: 20px;
  ${media.lessThan('medium')`
    display:none;
    z-index: 999;
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    padding: 0;
    margin: 0;
    height: unset;
    flex-direction: column;
    align-items: initial;
    &.show{
      display:block;
    }
  `}
`;

/**
 * Menu item element
 */
const MenuItem = styled.li`
  transition: 0.5s;
  
  &:hover {
    background: #a08066;
    border-radius: 5px;
  }
  ${media.lessThan('medium')`
    background-color: #795548;
    border: none;
    margin: 0 1px;
    font-weight: 600;
  `}
`;

/**
 * Menu item link element
 */
const MenuItemLink = styled.a`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  height: 100%;
  text-decoration: none !important;
  transition: background .2s linear;
  transition: color .2s linear;
  font-size: 14px;
  ${media.lessThan('medium')`
    color: white;
    font-size: 20px;
    justify-content: center;
  `}
`;

/**
 * Social links element
 */
const SocialLinks = styled.div`
  color: white;
  padding: 0 10px;
  font-size: 20px;
  cursor: pointer;
  ${media.lessThan('991px')`
    display: none;
  `}
`;

/**
 * Social link element
 */
const SocialLink = styled.a`
  padding: 0 3px;
  color: white
`;

/**
 * Button toggle element
 */
const ButtonToggle = styled.span`
  font-size: 28px;
  cursor: pointer;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  display:none;
  ${media.lessThan('medium')`
  	display: block;
  `}
`;


const LogotypeImage = styled.img`
  margin-left: 10px;
  height: 45px;
`;

/**
 * Component
 *
 * @param links
 * @param withSocialLinks
 * @return Element
 * @constructor
 */
export default function Bronze ({ links, withSocialLinks, logoText }) {
	const [visibilityMenu, setVisibilityMenu] = useState(false);

	return (
		<Container>
			<Content>
				{logoText !== 'custom' && <Logotype>
					<LogotypeLink href="#">
                        segodnya
						{' '}
						<LogotypeLabel>news</LogotypeLabel>
					</LogotypeLink>
				</Logotype>}
        {logoText === 'custom' && <Logotype>
            <LogotypeImage src={logoEs}/>
          </Logotype>}
				<Navigation>
					<ButtonToggle onClick={() => { setVisibilityMenu(!visibilityMenu); }}>{visibilityMenu ? '×' : '≡'}</ButtonToggle>
					<Menu className={visibilityMenu && 'show'}>
						{links.map((item, i) => (
							<MenuItem key={i}>
								<MenuItemLink href={item.href}>{item.text}</MenuItemLink>
							</MenuItem>
						))}
					</Menu>
				</Navigation>
				{withSocialLinks && (
					<SocialLinks>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faFacebookSquare} />
						</SocialLink>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faGooglePlusSquare} />
						</SocialLink>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faTwitterSquare} />
						</SocialLink>
					</SocialLinks>
				)}
			</Content>
		</Container>
	);
}

Bronze.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			href: PropTypes.string.isRequired
		})
	),
	withSocialLinks: PropTypes.bool,
  logoText: PropTypes.string
};

Bronze.defaultProps = {
	links: [],
	withSocialLinks: true,
  logoText: ''
};
