import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
	background: rgb(41, 41, 49);
`;

/**
 * Content element
 */
const Content = styled.div`
	padding: 2rem 3rem;
	color: #dedede;
	font-family: 'Open Sans', sans-serif;
	font-size: .93rem;
`;

/**
 * Brand element
 */
const Brand = styled.h1`
	margin-bottom: .6rem;
`;

/**
 * Copyright element
 */
const Copyright = styled.p`
	font-size: .87rem;
	color: #838383;
	margin: 0;
`;

/**
 * Footer element
 *
 * @param brand
 * @param year
 * @return Element
 * @constructor
 */
export default function Footer ({ brand, text, year }) {
	return (
		<Container>
			<Content>
				<Brand>{brand}</Brand>
				<Copyright>
					{text.replace('{year}', year)}
				</Copyright>
			</Content>
		</Container>
	);
}

Footer.propTypes = {
	brand: PropTypes.string.isRequired,
	year: PropTypes.number,
	text: PropTypes.string
};

Footer.defaultProps = {
	year: new Date().getFullYear(),
	text: `Copyright © ${new Date().getFullYear()}.
	Все права защищены.
	Для детей старше 16 лет.`
};
