import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.footer`
    border-top: 1px solid rgba(230, 81, 0, 0.9215686274509803);
    background: #a1a1a126;
`;

/**
 * Content element
 */
const Content = styled.div`
    text-align: center;
    color: rgb(222, 222, 222);
    font-family: "Open Sans", sans-serif;
    font-size: 0.93rem;
    padding: 2rem 3rem;
`;

/**
 * Privacy element
 */
const Privacy = styled.p`
    font-size: 0.87rem;
    color: #333;
    margin: 0;
`;

/**
 * Component
 *
 * @return Element
 * @constructor
 */
export default function Light ({year, text}) {
	return (
		<Container>
			<Content>
				<Privacy>
                    {text.replace('{year}', year)}
				</Privacy>
			</Content>
		</Container>
	);
}

Light.propTypes = {
	year: PropTypes.number,
	text: PropTypes.string,
};

Light.defaultProps = {
	year: (new Date()).getFullYear(),
	text: `Copyright © ${(new Date()).getFullYear()}. Все права защищены. Для детей старше 16 лет.`
};
