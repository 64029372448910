import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

/**
 * Container element
 */
const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.img`
  height: 190px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-size: cover;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  flex-direction: column;
  color: white;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  text-align: center;
  transition: 0.5s;
  ${Container}:hover & {
    filter: brightness(0.6);
  }
`;

/**
 * Content element
 */
const Content = styled.div`
  color: #656565;
  font-size: 14px;
  line-height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  padding: 15px;
  background: #ffffffcf;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: calc(100% - 190px);
`;

/**
 * Label element
 */
const Label = styled.span`
  border-bottom-left-radius: 5px;
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 2;
  min-width: 90px;
  padding: 3px 7px;
  box-sizing: border-box;
  font-size: 14px;
  background: #ff5722d4;
  text-align: center;
  color: white;
  font-family: monospace;
  font-weight: 900;
`;

/**
 * Continue button element
 */
const ContinueButton = styled.button`
  border: 1px solid #858282;
  padding: 8px 15px;
  color: #858282;
  background-color: transparent;
  width: max-content;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.5s;
  
  &:hover {
    color: #ffffff;
    background-color: #475562;
  }
`;

/**
 * ContentFooter element
 */
const ContentFooter = styled.div`
    margin-top: 8px;
    padding-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @param continueButton
 * @param label
 * @param stars
 * @return Element
 * @constructor
 */
export default function LightLarge ({ thumbnail, text, link, continueButton, label, stars }) {
	const redirect = () => window.location.replace(link);

	return (
		<Container>
			<Thumbnail src={thumbnail} alt={text}>
				{typeof label === 'object' && <Label>{label.text}</Label>}
			</Thumbnail>
			<Content>
				<span>{text}</span>
				<ContentFooter>
					{typeof continueButton === 'object' && (
						<ContinueButton onClick={redirect}>{continueButton.text}</ContinueButton>
					)}
					{stars && (
						<div>
							<FontAwesomeIcon icon={faStar} />
							<FontAwesomeIcon icon={faStar} />
							<FontAwesomeIcon icon={faStar} />
							<FontAwesomeIcon icon={faStar} />
						</div>
					)}
				</ContentFooter>
			</Content>
		</Container>
	);
}

LightLarge.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	continueButton: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	]),
	label: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	]),
	stars: PropTypes.bool
};

LightLarge.defaultProps = {
	continueButton: false,
	label: false,
	stars: false
};
