import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
  column-count: 5;
  column-gap: 1em;
  margin: 1.5em auto;
  max-width: 1160px;
  column-gap: 1.5em;
  column-rule: 1px solid #e6510038;
  column-gap: 35px;
  @media (min-width: 1024px) {
    column-count: 4;
  }
  @media (max-width: 1023px) and (min-width: 768px) {
    column-count: 3;
  }
  @media (max-width: 767px) and (min-width: 420px) {
    column-count: 2;
  }
  @media (max-width: 419px) and (min-width: 200px) {
    column-count: 1;
  }
  @media (max-width: 795px){
    margin: 1.5em auto;
    width: 100%;
    column-gap: 1.5em;
  }
`;

export default Container;
