import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.footer`
  background: rgb(41, 41, 49);
`;

/**
 * Content element
 */
const Content = styled.div`
  text-align: center;
  color: rgb(222, 222, 222);
  font-family: "Open Sans", sans-serif;
  font-size: 0.93rem;
  padding: 2rem 3rem;
`;

/**
 * Privacy element
 */
const Privacy = styled.p`
  font-size: 0.87rem;
  color: rgb(131, 131, 131);
  margin: 0;
`;

/**
 * Component
 *
 * @param year
 * @return Element
 * @constructor
 */
export default function Dark ({ year, text }) {
	return (
		<Container>
			<Content>
				<Privacy> {text.replace('{year}', year)} </Privacy>
			</Content>
		</Container>
	);
}

Dark.propTypes = {
	year: PropTypes.number,
	text: PropTypes.string
};

Dark.defaultProps = {
	year: (new Date()).getFullYear(),
	text: `Copyright © ${(new Date()).getFullYear()}. Все права защищены. Для детей старше 16 лет.`
};
