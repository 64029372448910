import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import TilesContainer from './TilesContainer';
import Tile from './Tile';

/**
 * Main element
 */
const Main = styled.main`
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 1rem;
    margin-top: 1rem;
    justify-content: center;
    @media (max-width: 795px){
        padding: 5px 10px;
    }
`;

/**
* Masonry element
*/
const Masonry = styled.div`

`;

/**
 * Component
 *
 * @param meta
 * @param header
 * @param footer
 * @param list
 * @return Element
 * @constructor
 */
export default function News ({ meta, header, footer, list }) {
	const tiles = list
		.map((item, index) => {
			const { template } = item;
			if (template === 'small') {
				return <Tile.Light {...item.data} key={index} />;
			}
			return null;
		})
		.filter(element => element !== null);
	return (
		<React.Fragment>
			<Helmet>
				<title>{ header.header_title ? header.header_title : meta.title }</title>
				<meta name="description" content={meta.description} />
			</Helmet>
			{header.template === 'light' && <Header.Light {...header.data} />}
			<Main>
				<TilesContainer.Container>
					{tiles}
				</TilesContainer.Container>
			</Main>
			{footer.template === 'light' && <Footer.Light {...footer.data} />}
		</React.Fragment>
	);
}

News.propTypes = {
	header: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	footer: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	list: PropTypes.arrayOf(PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	})).isRequired,
	meta: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	}).isRequired
};
