import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logotype from './logotype.png';
import logotypeEs from './logotypeEs.png';

/**
 * Container element
 */
const Container = styled.header`
    height: 50px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e65100;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    background: #f7f7f7;
    z-index: 20;
    position: relative;
    box-sizing: border-box;
`;

/**
 * Content element
 */
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    @media (max-width: 1319px) and (min-width: 1200px) {
        max-width: 1319px;
    }
`;

/**
 * LogoLink element
 */
const LogoLink = styled.a`
    font-family: monospace;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    text-decoration: none;
    margin-left: 20px;
`;

/**
 * Logotype element
 */
const Logotype = styled.img`
    width: 47px;
`;

/**
 * Menu element
 */
const Menu = styled.nav`
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #f0580d;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: -44px;
        bottom: 0;
        border: 25px solid transparent;
        border-right: 20px solid #f0580d;
    }
    @media (max-width: 795px) {
        &:after {
            display:none;
        }
        position: inherit;
        background-color: #f7f7f7;
        margin-right: 20px;
    }
    @media (min-width: 1320px){
        &:before {
            content: '';
            position: absolute;
            right: -44px;
            bottom: -1px;
            border: 25px solid transparent;
            border-left: 20px solid #f0580d;
        }
    }
`;

/**
 * MenuItems element
 */
const MenuItems = styled.ul`
    display: flex;
    margin: 0;
    list-style: none;
    height: 30px;
    align-items: center;
    padding: 0 20px;

    @media (max-width: 795px) {
        display: none;
        z-index: 999;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        height: unset;
        flex-direction: column;
        align-items: initial;

        &.show{
        display: block;
        }
    }
`;

/**
 * MenuList element
 */
const MenuList = styled.li`
    transition: 0.5s;
    &:hover {
        background: #fff;
    }
    @media (max-width: 795px) {
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        height: 40px;
        background-color: rgba(230, 81, 0, 0.9215686274509803);
        border: none;
        margin: 0 1px;
        height: 100%;
        font-weight: 600;
        &:hover{
            background: #ff5722c7;
        }
        &:last-child a{
            border-bottom: none;
        }
    }
`;

/**
 * MenuLink element
 */
const MenuLink = styled.a`
    font-family: monospace;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 17px 10px;
    height: 100%;
    text-decoration: none !important;
    -webkit-transition: background .2s linear;
    -o-transition: background .2s linear;
    transition: background .2s linear;
    -webkit-transition: color .2s linear;
    -o-transition: color .2s linear;
    transition: color .2s linear;
    font-size: 14px;
    
    &:hover {
        color: #f0580d;
        font-weight: bolder;
    }

    @media (max-width: 795px) {
        color: white;
        font-size: 15px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 13px 10px;
        width: calc(100% - 80px);
        text-align: center;
        border-bottom: 1px solid white;
        margin: 0 40px;

        &:active,&:focus{
            background: rgba(255, 87, 34, 0);
            outline: none;
        }
        &:hover{
            color: #f7f7f7;
        }
    }
`;

/**
 * Toggle button element
 */
const ToggleButton = styled.span`
    display: block;
    font-size: 38px;
    cursor: pointer;
    color: #e65100eb;
    font-family: Arial, Helvetica, sans-serif;
    display: none;
    @media (max-width: 795px){
        display: block;
    }
`;

/**
 * Component
 *
 * @param links
 * @return Element
 * @constructor
 */
export default function Light ({ links, logoText }) {
	const [visibilityMenu, setVisibilityMenu] = useState(false);

	return (
		<Container>
			<Content>
				<div>
					<LogoLink href="#">
						<Logotype src={logoText === 'custom' ? logotypeEs : logotype} />
					</LogoLink>
				</div>
				<Menu>
					<ToggleButton onClick={() => { setVisibilityMenu(!visibilityMenu); }}>{visibilityMenu ? '×' : '≡'}</ToggleButton>
					<MenuItems className={visibilityMenu && 'show'}>
						{links.map((item, index) => (
							<MenuList key={index}>
								<MenuLink href={item.href}>{item.text}</MenuLink>
							</MenuList>
						))}
					</MenuItems>
				</Menu>
			</Content>
		</Container>
	);
}

Light.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired,
        logoText: PropTypes.string
	}))
};

Light.defaultProps = {
	links: [],
    logoText: ''
};
