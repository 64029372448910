import { orderBy } from 'lodash-es';
import {
	SET_LOADING_STATE,
	UNSET_LOADING_STATE,
	DATA_FETCH_FAILURE,
	DATA_FETCH_SUCCESS,
	SET_NEW_LIST
} from '../actions/app';

const initialState = {
	loading: true,
	theme: '',
	data: {},
	error: null
};

/**
 * Export reducer for application
 *
 * @param state
 * @param action
 * @return Object
 */
export default function (state = initialState, action) {
	switch (action.type) {
		case SET_LOADING_STATE:
			return {
				...state,
				loading: true
			};
		case UNSET_LOADING_STATE:
			return {
				...state,
				loading: false
			};
		case DATA_FETCH_SUCCESS:
			const list = orderBy(
				action.payload.data.list,
				['position'],
				['ask']
			);
			
			return {
				...state,
				theme: action.payload.theme,
				data: {
					...action.payload.data,
					list,
					initalList: list,
				}
			};
		case DATA_FETCH_FAILURE:
			return {
				...state,
				error: action.payload
			};
		case SET_NEW_LIST:
			return {
				...state,
				theme: state.theme,
				data: {
					...state.data,
					list: action.payload,
				},
			};
		default:
			return state;
	}
}
