import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faGooglePlusG, faFacebook } from '@fortawesome/free-brands-svg-icons';
import logotype from './logotype.webp';
import backgroundImage from './background.webp';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.header`
    height: 65px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    background: url(${props => props.backgroundImage});
    z-index: 20;
`;

/**
 * Content element
 */
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 40px;
`;

/**
 * Brand section element
 */
const Brand = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * Logotype element
 */
const Logotype = styled.img`
    height: 45px;
`;

/**
 * Social links element
 */
const SocialLinks = styled.div`
    padding: 0 30px;
    font-size: 20px;
    cursor: pointer;
    ${media.lessThan('980px')`
        display: none;
    `}
`;

/**
 * Social link element
 */
const SocialLink = styled.a`
    font-size: 18px;
    padding: 0 5px;
    color: #d1d1d1;
`;

/**
 * Menu section element
 */
const Menu = styled.nav``;

/**
 * MenuItemsList section element
 */
const MenuItemsList = styled.ul`
    padding: 0;
    display: flex;
    list-style: none;
    height: 30px;
    align-items: center;
    ${media.lessThan('medium')`
        display: none;
        z-index: 999;
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        height: unset;
        flex-direction: column;
        align-items: initial;
        &.show{
        display:block;
        }
    `}
`;

/**
 * MenuItem section element
 */
const MenuItem = styled.li`
    &:hover{
        border-bottom: 2px solid #04a9e9;
    }
    ${media.lessThan('medium')`
        background-color: #795548;
        border: none;
        padding: 0 1px;
        font-weight: 600;
        width: 100%;
        box-sizing: border-box;
    `}

`;

/**
 * MenuLink section element
 */
const MenuLink = styled.a`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 100%;
    text-decoration: none !important;
    transition: background .2s linear;
    transition: color .2s linear;
    font-size: 14px;
    box-sizing: border-box;
    ${media.lessThan('medium')`
        color: white;
        font-size: 20px;
        justify-content: center;
        width: 100%;
    `}
`;

/**
 * Button toggle element
 */
const ButtonToggle = styled.span`
    display: none;
    font-size: 28px;
    cursor: pointer;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    ${media.lessThan('medium')`
        display: block;
    `}
`;

/**
 * Search section element
 */
const Search = styled.div`
    padding: 0 30px;
    font-size: 14px;
    cursor: pointer;
    ${media.lessThan('1080px')`
        display: none;
    `}
`;

/**
 * Search item element
 */
const SearchItem = styled.a`
    color: #d1d1d1;
    font-family: monospace;
    padding: 0 3px;
    text-decoration: none;
`;

/**
 * Component
 *
 * @param links
 * @return Element
 * @constructor
 */
export default function Dark ({ links }) {
	const [visibilityMenu, setVisibilityMenu] = useState(false);

	return (
		<Container backgroundImage={backgroundImage}>
			<Content>
				<Brand>
					<div>
						<Logotype src={logotype} />
					</div>
					<SocialLinks>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faTwitter} />
						</SocialLink>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faGooglePlusG} />
						</SocialLink>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faCamera} />
						</SocialLink>
						<SocialLink href="#">
							<FontAwesomeIcon icon={faFacebook} />
						</SocialLink>
					</SocialLinks>
				</Brand>
				<Menu >
					<ButtonToggle onClick={() => { setVisibilityMenu(!visibilityMenu); }}>{visibilityMenu ? '×' : '≡'}</ButtonToggle>
					<MenuItemsList className={visibilityMenu && 'show'}>
						{links.map((item, index) => (
							<MenuItem key={index}>
								<MenuLink href={item.href}>{item.text}</MenuLink>
							</MenuItem>
						))}
					</MenuItemsList>
				</Menu>
				<Search>
					<SearchItem href="#">
						<FontAwesomeIcon icon={faSearch} />
					</SearchItem>
					<SearchItem href="#">UA</SearchItem>
					<SearchItem href="#">RU</SearchItem>
				</Search>
			</Content>
		</Container>
	);
}

Dark.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired
	}))
};

Dark.defaultProps = {
	links: []
};
