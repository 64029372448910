import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHeart, faComment } from '@fortawesome/free-regular-svg-icons';
import moment from "moment";
import 'moment/locale/ru';

/**
 * Container element
 */
const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid lightgray;
	background-color: #fff;
	font-family: 
		'Open Sans', 
		-apple-system, 
		BlinkMacSystemFont, 
		"Segoe UI", 
		Roboto, 
		"Helvetica Neue", 
		Arial, 
		"Noto Sans", 
		sans-serif, 
		"Apple Color Emoji", 
		"Segoe UI Emoji", 
		"Segoe UI Symbol", 
		"Noto Color Emoji";
	transition: .2s;

	&:hover {
		box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		cursor: pointer;
  	}
`;

/**
 * Content element
 */
 const Content = styled.div`
	margin: 1.5rem 1rem;
	height: 100%;
`;

const Image = styled.img`
	height: 50%;
	background-repeat: no-repeat;
	background-size: cover;
`;

const Title = styled.h1`
	margin: 0;
	display: flex;
	align-items: center;
	height : 10%;
`;

/**
 * Text element
 */
 const Text = styled.p`
	font-size: 0.9rem;
	height: 30%;
	margin: 0;
	overflow: hidden;
	line-height: 1.3em;
	word-spacing: 0.3rem;
`;

const Social = styled.div`
	display: flex;
	height: 10%;
`;

const SocialItem = styled.p`
	display: block;
	font-size: 1.1rem;
	color: navy;
	padding-left: 1em!important;
	padding-right: 1em!important;
	color : ${props => props.color }!important;
	&:hover {
		color: navy!important;
	}
`;

/**
 * Small tile element
 *
 * @param thumbnail
 * @param text
 * @param link
 * @constructor
 */
export default function Giant ({ link, text, image, title, socialItems}) {
	const hasSocial = socialItems instanceof Object;

	return (
		<Container onClick={() => window.open(link)}>
			<Content>
				<Image src={image} alt={title}></Image>
				<Title>{title}</Title>
				<Text>{text}</Text>
				{ hasSocial
					? (<Social>
						<SocialItem color="navy">
							<FontAwesomeIcon icon={faCalendar} />
							&nbsp;
							{ moment().locale("ru").format("LL") }
						</SocialItem>
						<SocialItem color="red">
							<FontAwesomeIcon icon={faHeart} /> 
							&nbsp;
							{'likes' in socialItems ? socialItems.likes : 1053 }
						</SocialItem>
						<SocialItem color="gray">
							<FontAwesomeIcon icon={faComment} />
							&nbsp;
							{'comments' in socialItems ? socialItems.comments : 45 }
						</SocialItem>
					</Social>)
					: ''
				}
			</Content>
		</Container>
	);
};

Giant.propTypes = {
	link: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	socialItems: PropTypes.oneOfType([PropTypes.object]),
};

Giant.defaultProps = {
	contentPosition: 'right',
	withContinueButton: false,
	withLabel: false
};
