import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Styled item element
 */
const Item = styled.div`
  --height: 16.5rem;
  box-sizing: border-box;
  margin: 0.5rem;
  cursor: pointer;
  display: flex;
  
  ${media.lessThan('medium')`
  	min-height: var(--height);
  	height: auto;
  	width: 100%;
  `}
`;

/**
 * Small item element
 */
const Small = styled(Item)`
  width: calc(25% - 1rem);
`;

/**
 * Large item element
 */
const Large = styled(Item)`
  width: calc(50% - 1rem);
`;

export default {
	Large,
	Small
};
