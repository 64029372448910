import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.header`
  height: 50px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  background: #404449;
  z-index: 20;
  position: relative;
`;

/**
 * Content element
 */
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

/**
 * Logotype element
 */
const Logotype = styled.div``;

/**
 * LogotypeLink element
 */
const LogotypeLink = styled.a`
  font-family: monospace;
  font-size: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 20px;
  color: white;
  border-radius: 4px;
`;

/**
 * Logotype span element
 */
const LogotypeSpan = styled.span`
  margin-left: 2px;
  padding: 3px;
  color: white;
  background-color: #03A9F4;
  box-shadow: 1px 1px 3px #b5b1b1;
  border-radius: 5px;
`;

/**
 * Menu element
 */
const Menu = styled.nav`

`;

/**
 * Link list
 */
const LinkList = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  height: 30px;
  align-items: center;
  margin-right: 20px;
`;

/**
 * List item element
 */
const ListItem = styled.li`
  transition: 0.5s;
  
  &:hover {
    background: #03a9f4;
    border-radius: 5px;
  }
`;

/**
 * Item link element
 */
const Link = styled.a`
  font-family: monospace;
  color: white;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  height: 100%;
  text-decoration: none !important;
  transition: background .2s linear;
  font-size: 14px;
`;

/**
 * Component
 *
 * @param links
 * @return Element
 * @constructor
 */
export default function Dark ({ links, logoText }) {
	return (
		<Container>
			<Content>
			{logoText === '' &&	<Logotype>
					<LogotypeLink href='#'>
                        Segodnya
						{' '}
						<LogotypeSpan>News</LogotypeSpan>
					</LogotypeLink>
				</Logotype>}
      {logoText !== '' && <Logotype>
					<LogotypeLink href='#'>
                        {logoText.split(' ')[0]}
						{' '}
						<LogotypeSpan>{logoText.split(' ')[1]}</LogotypeSpan>
					</LogotypeLink>
				</Logotype>}
				<Menu>
					<LinkList>
						{links.map((item, index) => (
							<ListItem key={index}>
								<Link href={item.href}>{item.text}</Link>
							</ListItem>
						))}
					</LinkList>
				</Menu>
			</Content>
		</Container>
	);
};

Dark.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired
	})),
  logoText: ''
};

Dark.defaultProps = {
	links: [],
  logoText: ''
};
