import {
	DEBUG,
	INFO,
	NOTICE,
	WARNING,
	ERROR,
	CRITICAL,
	ALERT,
	EMERGENCY
} from './index';

/**
 * Browser console driver
 *
 * @param level
 * @param message
 * @param data
 */
export default function (level, message, data) {
	switch (level) {
		case INFO:
			console.info(message, data);
			break;
		case NOTICE:
		case WARNING:
			console.warn(message, data);
			break;
		case ERROR:
		case CRITICAL:
		case ALERT:
		case EMERGENCY:
			console.error(message, data);
			break;
		case DEBUG:
		default:
			console.log(message, data);
	}
}
