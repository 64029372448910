import axios from 'axios';
import env from './utils/env';

const defaultConfig = {
	baseURL: env('REACT_APP_API_URL', '')
};

/**
 * Client factory
 *
 * @param config
 * @return {AxiosInstance}
 */
export function factory (config = {}) {
	return axios.create({
		...defaultConfig,
		...config
	});
}

/**
 * Default client
 *
 * @return {AxiosInstance}
 */
export default function () {
	return factory();
}
