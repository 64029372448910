import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import Tile from '../Tile';
import VirtualGrid from 'classes/virtual-grid/VirtualGrid';

/**
 * Container element
 */
const Container = styled.div`
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  grid-template-columns: ${props => '1fr '.repeat(props.columns)};
  max-width: 1200px;
  margin: 0 auto;
`;

/**
 * Tile element
 */
const StyledTile = styled.div`
  --height: 16.5rem;
  min-height: 100%;
  height: var(--height);
  box-sizing: border-box;
  padding: .7rem;
  
  ${media.lessThan('medium')`
  	min-height: var(--height);
  	height: auto;
  `}
`;

const GridTileWrap = styled(StyledTile)`
	grid-column: ${props => props.columnStart}/${props => props.columnEnd};
	grid-row: ${props => props.rowStart}/${props => props.rowEnd};
`;

/**
 * Large tile element
 */
// const LargeTile = styled(StyledTile)`
//   width: 50%;

//   ${media.lessThan('medium')`
//   	width: 100%;
//   `}
// `;

/**
 * Multi tile element
 */
// const MultiTile = styled(LargeTile)``;

/**
 * Small tile element
 */
// const SmallTile = styled(StyledTile)`
//   width: 25%;

//   ${media.lessThan('medium')`
//   	width: 100%;
//   `}
// `;

/**
 * Render list of tiles
 *
 * @param list
 * @return Element
 * @constructor
 */
export default function TileList ({ list, gridColumnsQty }) {
	const virtualGrid = new VirtualGrid(gridColumnsQty);

	const getItemSize = item => {
		let x = 1;
		let y = 1;

		switch (item.type) {
			case 'small' :
				break;
			case 'large' :
			case 'multi' :
				x = 2;
				break;
			case 'giant' :
				x = 3;
				y = 3;
				break;
		}

		return [x, y];
	};

	list = list.filter(item => {
		let [x, y] = getItemSize(item);

		return x <= gridColumnsQty;
	});

	// to avoid duplicate coordinates for same objects
	list = list.map(item => Object.create(item));

	list.forEach(item => {
		let [x, y] = getItemSize(item);

		virtualGrid.appendElement(x, y, item);
	});
	
	const SelectedTile = ({ item, index }) => {
		switch (item.type) {
			case 'large':
				return <Tile.Large
					thumbnail={item.data.thumbnail}
					text={item.data.text}
					link={item.data.link}
					withLabel={item.data.withLabel}
					contentPosition={item.data.contentPosition}
					withContinueButton={item.data.withContinueButton}
				/>;
			case 'small':
				return <Tile.Small
					thumbnail={item.data.thumbnail}
					text={item.data.text}
					link={item.data.link}
				/>;
			case 'multi':
				return <Tile.Multi list={item.data.list}/>;
			case 'giant':
				return <Tile.Giant text={item.data.text}
					title={item.data.title}
					image={item.data.image}
					socialItems={item.data.socialItems}
					link={item.data.link}
					/>
			default:
				return null;
		}
	};

	return (
		<Container columns={gridColumnsQty}>
			{list.map((item, index) => {
				const { rowStart, rowEnd, columnStart, columnEnd } = virtualGrid.getElementCoordinates(item);

				return (
					<GridTileWrap rowStart={rowStart} rowEnd={rowEnd} columnStart={columnStart} columnEnd={columnEnd} key={index}>
						<SelectedTile item={item}/>
					</GridTileWrap>
				);
				
				// old realization on flexBox
				
				// switch (item.type) {
				// 	case 'small':
				// 		return (
				// 			<SmallTile key={index}>
				// 				<SelectedTile item={item}/>
				// 			</SmallTile>
				// 		);
				// 	case 'large':
				// 		return (
				// 			<LargeTile key={index}>
				// 				<SelectedTile item={item}/>
				// 			</LargeTile>
				// 		);
				// 	case 'multi':
				// 		return (
				// 			<MultiTile key={index}>
				// 				<SelectedTile item={item}/>
				// 			</MultiTile>
				// 		);
				// 	default:
				// 		return null;
				// }
			})}
		</Container>
	);
}

TileList.propTypes = {
	list: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.oneOf(['large', 'small', 'multi', 'giant']).isRequired,
		data: PropTypes.object.isRequired
	})).isRequired,
	gridColumnsQty : PropTypes.number
};
