import Large from './Large';
import Multi from './Multi';
import Small from './Small';
import Giant from './Giant';

export default {
	Large,
	Small,
	Multi,
	Giant
};
