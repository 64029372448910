import {createTokenRequest} from './api';
import logger, {DEBUG, ERROR} from "../logger";

export default class Server {
    /**
     * Store token to server
     *
     * @param token
     */
    static store(token) {
        createTokenRequest(token)
            .then(() => logger(DEBUG, 'Token stored!'))
            .catch(err => logger(ERROR, err));
    }
}