import React from 'react';
import Content from '../Content';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

/**
 * Row element
 */
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

/**
 * Col element
 */
const Col = styled.div`
  width: calc((100% / 4) * ${props => props.size});
  box-sizing: border-box;
  padding-left: .5rem;
  padding-right: .5rem;
`;

/**
 * Combine elements
 *
 * @return Element
 */
export default function () {
	return (
		<Container>
			<Row>
				<Col size={1}>
					<Content.Block />
				</Col>
				<Col size={2}>
					<Content.Block />
				</Col>
				<Col size={1}>
					<Content.Block />
				</Col>
			</Row>
			<Row>
				<Col size={2}>
					<Content.Block />
				</Col>
				<Col size={2}>
					<Content.Block />
				</Col>
			</Row>
			<Row>
				<Col size={1}>
					<Content.Block />
				</Col>
				<Col size={1}>
					<Content.Block />
				</Col>
				<Col size={1}>
					<Content.Block />
				</Col>
				<Col size={1}>
					<Content.Block />
				</Col>
			</Row>
		</Container>
	);
}
