import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.div`
  border-bottom: 1px solid #d6d6d6;
  width: 100%;
`;

/**
 * Label element
 */
const Label = styled.span`
  display: block;
  position: absolute;
  top: 20px;
  left: -5px;
  z-index: 2;
  min-width: 90px;
  padding: 3px 7px;
  box-sizing: border-box;
  font-size: 14px;
  background: #ff5722;
  text-align: center;
  color: white;
  font-family: monospace;
  font-weight: 900;
  box-shadow: 0 0 4px #454545;
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.img`
  transition: 0.5s;
  height: 190px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-size: cover;
      justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  flex-direction: column;
  color: white;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  text-align: center;
  ${Container}:hover & {
    filter: brightness(0.6);
  }
`;

/**
 * Content element
 */
const Content = styled.span`
  color: #656565;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: calc(100% - 190px);
  &.large {
    font-weight: 600;
  }
  ${media.lessThan('1045px')`
    padding: 10px;
    padding-bottom: 6px;
  `}
`;

/**
 * Continue button element
 */
const ContinueButton = styled.button`
  font-size: 15px;
  font-weight: 700;
  background: transparent;
  border: none;
  outline: none;
  color: #e4777e;
  cursor: pointer;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
  font-family: 'Balsamiq Sans', cursive;
  
  &:hover {
    color: #72393d;
  }
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @param label
 * @param large
 * @param continueButton
 * @return Element
 * @constructor
 */
export default function Light ({ thumbnail, text, link, label, continueButton, large }) {
	const redirect = () => window.location.replace(link);

	return (
		<Container onClick={() => typeof continueButton !== 'object' && redirect()}>

			<Thumbnail src={thumbnail} alt={text}>
				{typeof label === 'object' && <Label>{label.text}</Label>}
			</Thumbnail>
			<Content className={large && 'large'}>
				{text}
			</Content>
		</Container>
	);
}

Light.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	])
};

Light.defaultProps = {
	label: false,
	continueButton: false,
	large: false
};
