import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import TilesContainer from './TilesContainer';
import Tile from './Tile';

/**
 * Component
 *
 * @param meta
 * @param header
 * @param footer
 * @param list
 * @return Element
 * @constructor
 */
export default function DarkSegodnyaNews ({ meta, header, footer, list }) {
	const tiles = list
		.map((item, index) => {
			const { template } = item;

			if (template === 'dark-large') {
				return (
					<TilesContainer.Item.Large key={index}>
						<Tile.Dark {...item.data}/>
					</TilesContainer.Item.Large>
				);
			}
			if (template === 'dark-small') {
				return (
					<TilesContainer.Item.Small key={index}>
						<Tile.Dark {...item.data}/>
					</TilesContainer.Item.Small>
				);
			}
			return null;
		})
		.filter(element => element !== null);
	return (
		<React.Fragment>
			<Helmet>
				<title>{ header.header_title ? header.header_title : meta.title }</title>
				<meta name="description" content={meta.description}/>
			</Helmet>
			{header.template === 'dark' && <Header.Dark {...header.data}/>}
			<TilesContainer.Container>
				{tiles}
			</TilesContainer.Container>
			{footer.template === 'dark' && <Footer.Dark {...footer.data}/>}
		</React.Fragment>
	);
}

DarkSegodnyaNews.propTypes = {
	header: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	footer: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	list: PropTypes.arrayOf(PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	})).isRequired,
	meta: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	}).isRequired
};
