import VirtualGridElement from "./VirtualGridElement.js";

export default class VirtualGridColumn {
    #number = null;
    #element = null;

    constructor(number){
        this.#number = parseInt(number, 10);
    }

    set element(virtualGridElement){
        if (!(virtualGridElement instanceof VirtualGridElement)) {
            throw new Error("virtualGridElement must be an instance of VirtualGridElement");
        }

        if (!this.isEmpty) {
            throw new Error("element already exists");
        }

        this.#element = virtualGridElement;
    }

    get element(){
        return this.#element;
    }

    get number(){
        return this.#number;
    }

    get isEmpty(){
        return this.#element === null;
    }
}