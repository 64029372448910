import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logotype from '../../Header/Dark/logotype.webp';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.footer`
    display: flex;
    text-align: center;
    color: rgb(222, 222, 222);
    font-family: "Open Sans", sans-serif;
    font-size: 0.93rem;
    padding: 2rem 3rem;
    justify-content: space-between;
    align-items: center;
    background: #232323;
`;

/**
 * Menu element
 */
const Menu = styled.nav``;

/**
 * MenuItemList element
 */
const MenuItemList = styled.ul`
    padding: 0;
    display: flex;
    list-style: none;
    height: 30px;
    align-items: center;
    ${media.lessThan('886px')`
        display:none;
    `}
`;

/**
 * MenuItem element
 */
const MenuItem = styled.li``;

/**
 * MenuItem element
 */
const MenuLink = styled.a`
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: white;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 100%;
    text-decoration: none !important;
    transition: background .2s linear;
    transition: color .2s linear;
    font-size: 14px;
`;

/**
 * Component
 *
 * @return Element
 * @constructor
 */
export default function Dark ({ links }) {
	return (
		<Container>
			<Menu>
				<MenuItemList>
					{links.map((item, index) => (
						<MenuItem key={index}>
							<MenuLink href={item.href}>{item.text}</MenuLink>
						</MenuItem>
					))}
				</MenuItemList>
			</Menu>
			<div>
				<img src={logotype} />
			</div>
		</Container>
	);
}
Dark.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired,
		active: PropTypes.bool
	}))
};

Dark.defaultProps = {
	links: []
};
