import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
  font-family: 
  	'Open Sans', 
  	-apple-system, 
  	BlinkMacSystemFont, 
  	"Segoe UI", 
  	Roboto, 
  	"Helvetica Neue", 
  	Arial, 
  	"Noto Sans", 
  	sans-serif, 
  	"Apple Color Emoji", 
  	"Segoe UI Emoji", 
  	"Segoe UI Symbol", 
  	"Noto Color Emoji";
  height: 100%;
  transition: .2s;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: flex-end;
  background-image: url(${props => props.image});

  &:hover {
    background-image: url(${props => props.image});
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .25);
    cursor: pointer;
  }
`;

/**
 * Right content container element
 */
const RightContentContainer = styled(Container)`
  justify-content: flex-end;
`;

/**
 * Bottom content container element
 */
const BottomContentContainer = styled(Container)`
  justify-content: flex-start;
  align-items: flex-end;
`;

/**
 * Content element
 */
const Content = styled.div`
  display: block;
  width: 280px;
  height: auto;
  color: white;
  font-weight: bold;
  padding: .4rem .9rem;
  margin: 0;
`;

/**
 * Right content element
 */
const RightContent = styled(Content)`
  margin: 1rem .5rem;
`;

/**
 * Bottom content element
 */
const BottomContent = styled(Content)`
  margin: .5rem 1rem;
  width: 350px;
`;

/**
 * Label element
 */
const Label = styled.span`
  border-radius: 4px;
  background-color: #da4242;
  padding: .4rem .5rem;
  font-size: .75rem;
  text-transform: uppercase;
`;

/**
 * Text element
 */
const Text = styled.p`
  font-weight: bold;
  font-size: .94rem;
  display: block;
`;

/**
 * Continue button element
 */
const ContinueButton = styled.button`
  --default-background: rgb(14, 61, 143);
  --default-color: #fff;
  
  background: var(--default-background);
  color: var(--default-color);
  border: none;
  font-weight: bold;
  padding: .5rem .8rem;
  transition: .2s;

  &:hover {
    background: var(--default-color);
    color: var(--default-background);
    cursor: pointer;
  }
`;

/**
 * Large tile element
 *
 * @param props
 * @return Element
 * @constructor
 */
export default function Large (props) {
	const {
		thumbnail,
		text,
		link,
		contentPosition,
		withContinueButton,
		withLabel
	} = props;
	const angleGradient = () => {
		switch (contentPosition) {
			case 'bottom':
				return '180deg';
			default:
				return '90deg';
		}
	};
	const SelectedContainer = ({ children, angleGradient, image, onClick }) => {
		switch (contentPosition) {
			case 'bottom':
				return (
					<BottomContentContainer onClick={onClick} angleGradient={angleGradient} image={image}>
						{children}
					</BottomContentContainer>
				);
			default:
				return (
					<RightContentContainer onClick={onClick} angleGradient={angleGradient} image={image}>
						{children}
					</RightContentContainer>
				);
		}
	};
	const SelectedContent = ({ children }) => {
		switch (contentPosition) {
			case 'bottom':
				return (
					<BottomContent>
						{children}
					</BottomContent>
				);
			default:
				return (
					<RightContent>
						{children}
					</RightContent>
				);
		}
	};
	const onClick = () => window.open(link);

	return (
		<SelectedContainer
			onClick={() => !withContinueButton && onClick()}
			angleGradient={angleGradient()}
			image={thumbnail}
		>
			<SelectedContent>
				{withLabel
					? <Label>{withLabel.text.slice(0, 20)}</Label>
					: null}
				<Text>{text.slice(0, 220)}</Text>
				{withContinueButton
					? (
						<ContinueButton onClick={onClick}>
							{withContinueButton.text}
						</ContinueButton>
					)
					: null
				}
			</SelectedContent>
		</SelectedContainer>
	);
}

Large.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	contentPosition: PropTypes.oneOf(['right', 'bottom']),
	withLabel: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	]),
	withContinueButton: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	])
};

Large.defaultProps = {
	contentPosition: 'right',
	withContinueButton: false,
	withLabel: false
};
