import { fetchDataRequest } from '../api';

export const SET_LOADING_STATE = 'app/set_loading_state';
export const UNSET_LOADING_STATE = 'app/unset_loading_state';
export const DATA_FETCH_SUCCESS = 'app/data_fetch_success';
export const DATA_FETCH_FAILURE = 'app/data_fetch_failure';
export const SET_NEW_LIST = 'app/set_new_list';

/**
 * Set loading state
 *
 * @param state
 * @return {{type: string}}
 */
export const setLoading = state => ({
	type: state ? SET_LOADING_STATE : UNSET_LOADING_STATE
});

/**
 * Create success data for state
 *
 * @param data
 * @return {{payload: *, type: string}}
 */
export const fetchDataSuccess = data => ({
	type: DATA_FETCH_SUCCESS,
	payload: data
});

/**
 * Create error data for state
 *
 * @param error
 * @return {{payload: *, type: string}}
 */
export const fetchDataFailure = error => ({
	type: DATA_FETCH_FAILURE,
	payload: error
});

/**
 * Fetch data from api
 *
 * @param hash
 * @return Function
 */
export const fetchData = hash => dispatch => {
	dispatch(setLoading(true));

	return fetchDataRequest(hash)
		.then(response => response.data)
		.then(data => {
			dispatch(fetchDataSuccess(data));
			dispatch(setLoading(false));
		})
		.catch(error => {
			dispatch(fetchDataFailure(error));
			dispatch(setLoading(false));
		});
};

export const setNewList = list => dispatch => new Promise((res, rej) => res(dispatch({ type: SET_NEW_LIST, payload: list})));
