import env from 'utils/env';
import browserConsole from './browserConsole';

export const DEBUG = 0;
export const INFO = 1;
export const NOTICE = 2;
export const WARNING = 3;
export const ERROR = 4;
export const CRITICAL = 5;
export const ALERT = 6;
export const EMERGENCY = 7;

/**
 * Logger
 *
 * @param level
 * @param message
 * @param data
 */
export default function logger (level, message, data = {}) {
	if (level >= parseInt(env('REACT_APP_LOG_THRESHOLD', 0))) {
		switch (env('REACT_APP_LOGGER_DRIVER', '')) {
			case 'console':
				browserConsole(level, message, data);
				break;
			default:
				browserConsole(WARNING, 'Logger driver unknown or undefined.');
				browserConsole(INFO, 'Set logger driver: browser console');
				browserConsole(level, message, data);
		}
	}
};
