import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from './Header';
import background from './background.webp';
import TilesContainer from './TilesContainer';
import Footer from './Footer';
import Tile from './Tile';
import media from 'styled-media-query';

/**
 * Main element
 */
const Main = styled.main`
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: start;
    background: url(${props => props.background});
    background-position: 0 0;
    background-size: cover;
    position: relative;
    letter-spacing: .5px;
`;

/**
 * Breadcrumbs element
 */
const Breadcrumbs = styled.div`
    width: 86.5%;
    margin-left: 8%;
`;

/**
 * BreadcrumbItem element
 */
const BreadcrumbLink = styled.a`
    font-size: 12px;
    color: #b9b9b9;
    line-height: 1.2;
    text-decoration: none;
    padding-right: 3px;
    padding-left: 3px;
`;

/**
 * TitleWrap element
 */
const TitleWrap = styled.a`
    width: 100%;
    padding: 0 40px;
`;

/**
 * TitleFirst element
 */
const TitleFirst = styled.h1`
    font-size: 30px;
    color: #3e4b58;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    display: inline-block;
    border-bottom: 2px #04a9e9 solid;
    padding-bottom: 4px;
`;

/**
 * TitleSecond element
 */
const TitleSecond = styled.h2`
    font-size: 20px;
    color: #3e4b58;
    text-transform: uppercase;
    margin-bottom: 35px;
    font-weight: 100;
`;

/**
 * SmallContent element
 */
const SmallContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    width: 65%;
    padding-bottom: 40px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    ${media.lessThan('746px')`
        margin: 0 auto
    `}
`;

/**
 * LargeContent element
 */
const LargeContent = styled.div`
    width: 33.3%;
    margin-right: 2%;
    position: relative;
    z-index: 100;
    ${media.lessThan('746px')`
        width: 100%;
        margin-right: 0;
    `}
`;

/**
 * Component
 *
 * @param meta
 * @param header
 * @param footer
 * @param list
 * @return Element
 * @constructor
 */
export default function Priamyi ({ meta, header, footer, list }) {
	const smallTiles = list
		.map((item, index) => {
			const { template } = item;

			if (template === 'light-small') {
				return (
					<TilesContainer.Item.Small key={index}>
						<Tile.Light {...item.data} />
					</TilesContainer.Item.Small>
				);
			}
			return null;
		})
		.filter(element => element !== null);

	const largeTiles = list
		.map((item, index) => {
			const { template } = item;
			if (template === 'light-large') {
				return (
					<TilesContainer.Item.Large key={index}>
						<Tile.Light {...item.data} large={true} />
					</TilesContainer.Item.Large>
				);
			}
			return null;
		})
		.filter(element => element !== null);

	return (
		<React.Fragment>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.description} />
			</Helmet>
			{header.template === 'dark' && <Header.Dark {...header.data} />}
			<Main background={background}>
				<Breadcrumbs>
					<p>
						<span>
							<BreadcrumbLink href="#">Главная »</BreadcrumbLink>
						</span>
						<span>
							<BreadcrumbLink href="#">Новости »</BreadcrumbLink>
						</span>
						<span>
							<BreadcrumbLink href="#">Киев</BreadcrumbLink>
						</span>
					</p>
				</Breadcrumbs>
				<TitleWrap>
					<TitleFirst>Киев</TitleFirst>
					<TitleSecond> Новости по рубрике «Киев»</TitleSecond>
				</TitleWrap>
				<TilesContainer.Container>
					<SmallContent>
						{smallTiles}
					</SmallContent>
					<LargeContent>
						{largeTiles}
					</LargeContent>
				</TilesContainer.Container>
			</Main>
			{footer.template === 'dark' && <Footer.Dark {...header.data} />}
		</React.Fragment>
	);
}

Priamyi.propTypes = {
	header: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	footer: PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	}).isRequired,
	list: PropTypes.arrayOf(PropTypes.shape({
		template: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	})).isRequired,
	meta: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	}).isRequired
};
