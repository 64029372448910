import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import env from 'utils/env';
import rootReducer from './reducers';

const middleware = applyMiddleware(thunkMiddleware);

export default createStore(
	rootReducer,
	env('NODE_ENV', 'production') === 'production'
		? middleware
		: composeWithDevTools(middleware)
);
