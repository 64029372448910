import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.div`
    display: flex;
    width: 100%;
    margin-left: 2%;
    position: relative;
  
  ${media.lessThan('746px')`
    display: block;
  `}
`;

export default Container;
