import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.div`
  border-radius: 12px;
  padding: .3rem;
  background: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.26);
  height: 100%;
  box-sizing:border-box;
`;

/**
 * Row element
 */
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

/**
 * Col element
 */
const Col = styled.div`
  margin: .5rem 1rem;
  width: calc(50% - 2rem);
  
  ${media.lessThan('medium')`
  	width: calc(100% - 2rem);
  `}
`;

/**
 * Media element
 */
const Media = styled.div`
  display: flex;
  flex-direction: row;
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.div`
  display: block;
`;

/**
 * Image element
 */
const Image = styled.img`
  width: 68px;
  height: 50px;
  object-fit: cover;
  
  &:hover {
  	cursor: pointer;
  }
`;

/**
 * Content element
 */
const Content = styled.div`
  margin-left: .8rem;
  display: block;
  color: rgb(32, 64, 126);
  font-family: 
  	'Open Sans', 
  	-apple-system, 
  	BlinkMacSystemFont, 
  	"Segoe UI", 
  	Roboto, 
  	"Helvetica Neue", 
  	Arial, 
  	"Noto Sans", 
  	sans-serif, 
  	"Apple Color Emoji", 
  	"Segoe UI Emoji", 
  	"Segoe UI Symbol", 
  	"Noto Color Emoji";
  font-size: .8rem;
  
  &:hover {
    color: red;
    cursor: pointer;
  }
`;

/**
 * Separator element
 */
const Separator = styled.hr`
  border: 1px solid rgba(0,0,0,.05);
`;

/**
 * Multi tile element
 *
 * @param list
 * @return Element
 * @constructor
 */
export default function Multi ({ list }) {
	const size = 2;
	const chunks = Array.from({ length: Math.ceil(list.length / size) }, (v, i) =>
		list.slice(i * size, i * size + size)
	);

	return (
		<Container>
			{chunks.map((items, index) => {
				const cols = items.map((item, indx) => {
					const onClick = () => window.open(item.link);

					return (
						<Col key={indx}>
							<Media>
								<Thumbnail>
									<Image onClick={onClick} src={item.thumbnail} alt="Thumbnail"/>
								</Thumbnail>
								<Content onClick={onClick}>{item.text}</Content>
							</Media>
						</Col>
					);
				});

				return (
					<React.Fragment key={index}>
						<Row>{cols}</Row>
						{index < chunks.length - 1 ? <Separator/> : null}
					</React.Fragment>
				);
			})}
		</Container>
	);
}

Multi.propTypes = {
	list: PropTypes.arrayOf(PropTypes.shape({
		thumbnail: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		link: PropTypes.string.isRequired
	})).isRequired
};
