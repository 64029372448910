import { connect } from 'react-redux';
import App from '../components/App';
import { isLoading, isError, theme, data } from '../selectors/app';
import { fetchData, setNewList } from '../actions/app';

const mapStateToProps = state => ({
	isLoading: isLoading(state),
	theme: theme(state),
	data: data(state),
	isError: isError(state)
});
const mapDispatchToProps = dispatch => ({
	fetchData: id => dispatch(fetchData(id)),
	setNewList: list => dispatch(setNewList(list)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
