import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Styled item element
 */
const Item = styled.div`
    box-sizing: border-box;
    margin: 0.5rem;
    cursor: pointer;
    display: flex;
  
  ${media.lessThan('886px')`
  	width: 100%;
  `}
  ${media.lessThan('746px')`
    padding: 0.5rem;
    margin:0;
  `}
`;

/**
 * Small item element
 */
const Small = styled(Item)`
  width: calc(30% - 0rem);
`;

/**
 * Large item element
 */
const Large = styled(Item)`
  width: 100%;
`;

export default {
	Large,
	Small
};
