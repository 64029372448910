import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  transition: .5s;
  position: relative;
  z-index: 3;
  color: white;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
`;

/**
 * Label element
 */
const Label = styled.span`
  display: block;
  position: absolute;
  top: -10px;
  left: -44px;
  z-index: 2;
  min-width: 125px;
  padding: 5px 24px;
  padding-top: 40px;
  box-sizing: border-box;
  font-size: 16px;
  background: #e4777e;
  text-align: center;
  color: white;
  font-family: monospace;
  font-weight: 900;
  transform: rotate(-45deg);
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.img`
  height: 230px;
  background-size: cover;
  background-position: center;
`;

/**
 * Content element
 */
const Content = styled.div`
  font-size: 14px;
  width: 100%;
  color: #333938;
  padding: 10px 0;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 230px);
  font-family: 'Balsamiq Sans', cursive;
`;

/**
 * Continue button element
 */
const ContinueButton = styled.button`
  font-size: 15px;
  font-weight: 700;
  background: transparent;
  border: none;
  outline: none;
  color: #e4777e;
  cursor: pointer;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
  font-family: 'Balsamiq Sans', cursive;
  
  &:hover {
    color: #72393d;
  }
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @param label
 * @param continueButton
 * @return Element
 * @constructor
 */
export default function Light ({ thumbnail, text, link, label, continueButton }) {
	const redirect = () => window.location.replace(link);

	return (
		<Container onClick={() => typeof continueButton !== 'object' && redirect()}>
			{typeof label === 'object' && <Label>{label.text}</Label>}
			<Thumbnail src={thumbnail} alt={text}/>
			<Content>
				{text}
				{typeof continueButton === 'object' && (
					<div>
						<ContinueButton onClick={redirect}>{continueButton.text}</ContinueButton>
					</div>
				)}
			</Content>
		</Container>
	);
}

Light.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	]),
	continueButton: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	])
};

Light.defaultProps = {
	label: false,
	continueButton: false
};
