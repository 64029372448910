import VirtualGridColumn from "./VirtualGridColumn.js";

export default class VirtualGridRow {
    #columns = [];
    #number = null;

    constructor(number, maxColumn){
        this.#number = parseInt(number, 10);
        
        for (let i = 1; i<= maxColumn; i++) {
            this.#columns.push(new VirtualGridColumn(i));
        }
    }

    get number(){
        return this.#number;
    }

    hasSpace(width){
        let needColumns = width;

        for (let VirtualGridColumn of this.#columns) {
            if (VirtualGridColumn.isEmpty) {
                needColumns--;
            } else {
                needColumns = width;
            }

            if (!needColumns) {
                return true;
            }
        }

        return false;
    }

    hasSpaceAfterColumn(width, column){
        let needColumns = width;
        const appropriateColumns = this.#columns.filter(col => col.number >= column);

        for (let VirtualGridColumn of appropriateColumns) {
            if (VirtualGridColumn.isEmpty) {
                needColumns--;
            } else {
                return false;
            }

            if (!needColumns) {
                return true;
            }
        }

        return false;
    }

    getFreeColumnsForWidth(width){
        let needColumns = width;
        let startColumn = null;

        const freeColumns = [];

        for (let VirtualGridColumn of this.#columns) {
            if (VirtualGridColumn.isEmpty) {
                if (startColumn === null) {
                    startColumn = VirtualGridColumn.number;
                }
                needColumns--;
            } else {
                needColumns = width;
                startColumn = null;
            }

            if (!needColumns) {
                freeColumns.push(startColumn);
                needColumns++;
                startColumn++
            }
        }

        return freeColumns;
    }

    appendElementToColumn(virtualGridElement, column){
        const VirtualGridColumn = this.#columns.find(col => col.number === column);

        VirtualGridColumn.element = virtualGridElement;
    }
}
