import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

/**
 * Item element
 */
const Item = styled.div`
  display: inline-block;
  width: 100%;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e6510038;
  background: #fff;
  margin: 0 0 1.5em;
  cursor:pointer;
  &:hover p{
    color: #e65100;
  }
`;

/**
 * Thumbnail element
 */
const Thumbnail = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-height: 300px;
  @media (max-width: 419px) and (min-width: 376px) {
    max-height: 420px;
  }
  @media (max-width: 375px) and (min-width: 200px) {
    max-height: 360px;
  }
`;

/**
 * Content element
 */
const Content = styled.p`
  color: #333;
`;

/**
 * Content element
 */
const Title = styled.h2`
  margin: 0;
  color: #e65100;
  font-size: 17px;
  line-height: 19px;
  &:hover{
    color: #e57a3f;
  }
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @param label
 * @param continueButton
 * @return Element
 * @constructor
 */
export default function Light ({ thumbnail, text, link, title }) {
	const redirect = () => window.location.replace(link);

	return (
		<Item onClick={() => redirect()}>
			<Thumbnail src={thumbnail} />
			<br />
			<Title>
				{title}
			</Title>
			<Content>
				{text}
			</Content>
		</Item>
	);
}

Light.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
};
