import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Content element
 */
const Content = styled.div`
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-size: cover;
  background-image: url(${props => props.backgroundImage});
  justify-content: flex-end;
  background-repeat: no-repeat;
  box-shadow: 0 0 2px #625f5f;
  background-position: center;
  margin: 3px;
  transition: .5s;
  position: relative;
  z-index: 3;
  flex-direction: column;
  color: white;
  font-size: 14px;
  line-height: 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.02);
  }
`;

/**
 * Label element
 */
const Label = styled.span`
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: block;
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 2;
  min-width: 90px;
  padding: 3px 7px;
  box-sizing: border-box;
  font-size: 14px;
  background: #ff5722d4;
  text-align: center;
  color: white;
  font-family: monospace;
  font-weight: 900;
`;

/**
 * Description element
 */
const Description = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 8px;
  background: #424040cf;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 14px;
`;

/**
 * Button element
 */
const Button = styled.button`
  border-radius: 5px;
  border: 1px solid #ffffffa8;
  padding: 8px 15px;
  color: white;
  background-color: transparent;
  width: max-content;
  margin: 0 auto;
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  display: block;
  
  &:hover {
    color: #504d4d;
    background-color: #fdfdfd;
  }
`;

/**
 * Component
 *
 * @param thumbnail
 * @param text
 * @param link
 * @param label
 * @return Element
 * @constructor
 */
export default function Dark ({ thumbnail, text, link, label, continueButton }) {
	const redirect = () => window.location.replace(link);

	return (
		<Content
			onClick={() => typeof continueButton !== 'object' && redirect()}
			backgroundImage={thumbnail}
		>
			{typeof label === 'object' && <Label>{label.text}</Label>}
			<Description>
				{text}
				{typeof continueButton === 'object' &&
                <Button onClick={redirect}>{continueButton.text}</Button>}
			</Description>
		</Content>
	);
}

Dark.propTypes = {
	thumbnail: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	]),
	continueButton: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			text: PropTypes.string.isRequired
		})
	])
};

Dark.defaultProps = {
	label: false,
	continueButton: false
};
