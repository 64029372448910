import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

/**
 * Animating for placeholder
 *
 * @type {Keyframes}
 */
const animation = keyframes`
    from { 
      background-position: -468px 0 
    }
    to { 
      background-position: +468px 0 
    }
`;

/**
 * Rectangle element
 */
const Rectangle = styled.div`
    display: inline-block;
    width: ${props => props.width};
    height: ${props => props.height};  
    border-radius: 2px;
    background: rgb(215, 215, 215);
    background: linear-gradient(
        90deg, 
        #e8e8e8 20%, 
        #dfdfdf 60%,
        #e8e8e8 80%
    );
    animation-name: ${animation};
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    will-change: auto;
`;

Rectangle.propTypes = {
	width: PropTypes.string.isRequired,
	height: PropTypes.string.isRequired
};

export default Rectangle;
