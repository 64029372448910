import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

/**
 * DarkHeader element
 *
 * @param brand
 * @param links
 * @return Element
 * @constructor
 */
export default function Default ({ brand, links }) {
	const [isVisibleDropdownMenu, setVisibleDropdownMenu] = React.useState(false);

	return (
		<header className={styles.header}>
			<div className={styles.wrap}>
				<div>
					<a className={styles.logo} href="#">{brand}</a>
				</div>
				<nav className={isVisibleDropdownMenu ? styles.show : ''}>
					<span onClick={() => setVisibleDropdownMenu(!isVisibleDropdownMenu)} className={styles.btn_toggle}>
						{isVisibleDropdownMenu ? <span>&times;</span> : <span>&equiv;</span>}
					</span>
					<ul className={styles.menu}>
						{links.map((link, index) => (
							<li key={index} className={styles.item}>
								<a className={styles.link} href={link.href}>{link.text}</a>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</header>
	);
};

Default.propTypes = {
	brand: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired
	}))
};

Default.defaultProps = {
	brand: '',
	links: []
};
