import TarkNews from './TarkNews';
import LightSegodnyaNews from './LightSegodnyaNews';
import DarkSegodnyaNews from './DarkSegodnyaNews';
import BronzeSegodnyaNews from './BronzeSegodnyaNews';
import Priamyi from './Priamyi';
import News from './News';

export default {
	TarkNews,
	LightSegodnyaNews,
	DarkSegodnyaNews,
	BronzeSegodnyaNews,
	Priamyi,
	News
};
