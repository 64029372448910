import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import styled from 'styled-components';
import logger, { ERROR } from 'logger';
import Theme from '../Theme';
import Placeholder from '../Placeholder';
import 'scss/app.scss';

import Push from 'push-js';
import firebase from '../../push/firebase';
import Server from '../../push/Server';
import _ from 'lodash';

/**
 * Main UI component
 */
export default class App extends Component {
	/**
     * Is invoked immediately after a component is mounted
     * (inserted into the tree). Initialization that requires
     * DOM nodes should go here. If you need to load data
     * from a remote endpoint, this is a good place to instantiate
     * the network request
     */
	componentDidMount () {
		const params = QueryString.parse(window.location.search);
		const { fetchData, setNewList } = this.props;
		const getMaxScrollHeight = () => document.body.offsetHeight - window.innerHeight;
		const makeExtendedDuplicatedList = () => _.concat(this.props.data.list, _.shuffle(this.props.data.initalList));

		fetchData('hash' in params ? params.hash : '');
		
		//infinity scroll
		window.onscroll = (e) => {
			const maxScrollHeight = getMaxScrollHeight();
			const currentScroll = window.scrollY;

			if (this.props.data.list ?? null) {
				const scrollPercent = _.round(currentScroll * 100 / maxScrollHeight);

				if (scrollPercent >= 90) {
					setNewList(makeExtendedDuplicatedList());
				}
			}
		};
	}

	/**
     * Error boundaries are React components that catch JavaScript
     * errors anywhere in their child component tree, log those errors,
     * and display a fallback UI instead of the component tree that
     * crashed. Error boundaries catch errors during rendering,
     * in lifecycle methods, and in constructors of the whole tree
     * below them.
     *
     * @param error
     * @param errorInfo
     */
	componentDidCatch (error, errorInfo) {
		logger(ERROR, errorInfo, error);
	}

	/**
     * Get error message component
     *
     * @return Element
     */
	getErrorComponent () {
		const ErrorMessage = styled.div`
          text-align: center;
          margin: 4rem 1rem;
          font-family: 
            'Open Sans', 
            -apple-system, 
            BlinkMacSystemFont, 
            "Segoe UI", 
            Roboto, 
            "Helvetica Neue", 
            Arial, 
            "Noto Sans", 
            sans-serif, 
            "Apple Color Emoji", 
            "Segoe UI Emoji", 
            "Segoe UI Symbol", 
            "Noto Color Emoji";
        `;
		return (
			<ErrorMessage>
				<h2>Woops:(</h2>
				<p>Произошла ошибка, или для Вас ничего нет!</p>
			</ErrorMessage>
		);
	}

	/**
     * Get loader component
     *
     * @return Element
     */
	getLoaderComponent () {
		return (
			<Fragment>
				<Placeholder.Container>
					<Placeholder.Header/>
					<Placeholder.Body/>
				</Placeholder.Container>
			</Fragment>
		);
	}

	/**
     * Get selected theme component
     *
     * @return Element
     */
	getThemeComponent () {
		const { theme, data } = this.props;

		return (
			<Fragment>
				{theme === 'tark-news' && <Theme.TarkNews {...data}/>}
				{theme === 'light-segodnya-news' && <Theme.LightSegodnyaNews {...data}/>}
				{theme === 'dark-segodnya-news' && <Theme.DarkSegodnyaNews {...data}/>}
				{theme === 'bronze-segodnya-news' && <Theme.BronzeSegodnyaNews {...data}/>}
				{theme === 'priamyi' && <Theme.Priamyi {...data}/>}
				{theme === 'news' && <Theme.News {...data}/>}
			</Fragment>
		);
	}

	/**
     * Template for render
     *
     * @return jsx
     */
	render () {
		let pushState = {};
		if (typeof Notification !== 'undefined') {
			pushState = Push.getPermissionState();
		}
		const messaging = firebase.messaging();
		const { isLoading, isError, data } = this.props;

		if (isLoading) {
			return this.getLoaderComponent();
		} else if (isError) {
			return this.getErrorComponent();
		}

		if (Object(pushState).keys !== 0 && pushState?.id === 'PERMISSION_PROMPT' && data.collect_push_subscribers) {
			messaging
				.requestPermission()
				.then(() => {
					messaging.getToken()
						.then(currentToken => {
							Server.store(currentToken);
						});
				})

		}


		return this.getThemeComponent();
	}
}

App.propTypes = {
	theme: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.bool.isRequired,
	fetchData: PropTypes.func.isRequired,
	setNewList: PropTypes.func.isRequired,
};
