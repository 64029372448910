import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';

/**
 * Container element
 */
const Container = styled.header`
  background-color: #f1f1f1;
  box-shadow: 0 1px 3px 0px #0000002e;
`;

/**
 * Content element
 */
const Content = styled.div`
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lessThan('medium')`
    height: 60px;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
  
`;

/**
 * Logotype element
 */
const Logotype = styled.div``;

/**
 * Logotype link element
 */
const LogotypeLink = styled.a`
  text-decoration: none;
  color: #282e3f;
  text-transform: uppercase;
  font-family: 'Piedra', cursive;
  font-size: 30px;
  display: block;
  margin-bottom: 10px;
  ${media.lessThan('medium')`
    margin-bottom: 0;
  `}
`;

/**
 * Menu element
 */
const Menu = styled.nav``;

/**
 * Menu item list element
 */
const MenuItemList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  ${media.lessThan('991px')`
    font-size: 15px;
  `}
  ${media.lessThan('medium')`
    display: none;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 11;
    background-color: #d1d1d1;
    box-shadow: 0 1px 3px 0px #0000002e;
    &.show{
      display:block;
    }
  `}
`;

/**
 * Menu item element
 */
const MenuItem = styled.li`
  position: relative;
  
  &:after {
    content: '/';
    position: absolute;
    top: 7px;
    right: -2px;
    color: #282e3f;
    font-size: 21px;
    ${media.lessThan('medium')`
      display:none
    `}
  }
  &:last-child:after {
    content: '';
  }
`;

/**
 * Menu link element
 */
const MenuLink = styled.a`
  text-decoration: none;
  display: block;
  color: #d68c8c;
  box-sizing: border-box;
  padding: 10px 15px;
  margin: 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  transition: .25s ease-in-out;
  position: relative;

  &:hover,&.active{
    background-color: #282e3f;
    color: white;
  }

  ${media.lessThan('medium')`
    padding: 5px 13px;
    margin: 0;
    &:after{
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 26px);
      bottom: -1px;
      left: 13px;
      box-sizing: border-box;
      border-bottom: 1px solid #c9c9c9;
    }
  `}

`;

/**
 * Button toggle element
 */
const ButtonToggle = styled.button`
  font-size: 30px;
  cursor: pointer;
  display:none;
  background: transparent;
  outline: none;
  border: none;
  ${media.lessThan('medium')`
  	display: block;
  `}

`;

/**
 * Component
 *
 * @param links
 * @return Element
 * @constructor
 */
export default function Light ({ links, logoText }) {
	const [visibilityMenu, setVisibilityMenu] = useState(false);

	return (
		<Container>
			<Content>
				<Logotype>
					<LogotypeLink>{logoText}</LogotypeLink>
				</Logotype>
				<Menu>
					<ButtonToggle onClick={() => { setVisibilityMenu(!visibilityMenu); }}>{visibilityMenu ? '×' : '≡'}</ButtonToggle>
					<MenuItemList className={visibilityMenu && 'show'}>
						{links.map((item, index) => (
							<MenuItem key={index}>
								<MenuLink href={item.href} className={item.active && 'active'}>{item.text}</MenuLink>
							</MenuItem>
						))}
					</MenuItemList>
				</Menu>
			</Content>
		</Container>
	);
}

Light.propTypes = {
	links: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		href: PropTypes.string.isRequired,
		active: PropTypes.bool,
    logoText: PropTypes.string
	}))
};

Light.defaultProps = {
	links: [],
  logoText: 'segodnya-news'
};
