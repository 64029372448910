export default class VirtualGridElement {
    #rowStart = null;
    #rowEnd = null;
    #columnStart = null;
    #columnEnd = null;
    #element = null;

    constructor(rowStart, rowEnd, columnStart, columnEnd, element){
        this.#rowStart = rowStart;
        this.#rowEnd = rowEnd;
        this.#columnStart = columnStart;
        this.#columnEnd = columnEnd;
        this.#element = element;
    }

    get coordinates(){
        return {
            rowStart : this.#rowStart,
            rowEnd : this.#rowEnd,
            columnStart : this.#columnStart,
            columnEnd : this.#columnEnd,
        };
    }

    get element(){
        return this.#element;
    }
}
