import React from 'react';
import styled from 'styled-components';
import Rectangle from '../Rectangle';

/**
 * DarkHeader element
 */
const Header = styled.header`
  background: white;
  box-shadow: 0 2px 15px rgba(0,0,0,.09);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: .5rem 1.5rem;
`;

/**
 * Brand element
 */
const Brand = styled.div`
  display: block;
  text-align: left;
`;

/**
 * Menu element
 */
const Menu = styled.nav`
  display: block;
  text-align: right;
`;

/**
 * Menu items element
 */
const MenuItems = styled.ul`
  list-style: none;
`;

/**
 * Menu item element
 */
const MenuItem = styled.li`
  display: inline;
  margin-left: 1rem;
  margin-right: 1rem;
      
  &:first-child {
    margin-left: 0;
  }    
  &:last-child {
    margin-right: 0;
  }
`;

/**
 * Combine elements
 *
 * @return Element
 */
export default function () {
	const menuItems = [];
	for (let i = 0; i < 5; ++i) {
		const randomWidth = (Math.floor(Math.random() * (100 - 50)) + 50) + 'px';
		const height = '10px';
		menuItems.push((
			<MenuItem key={i}>
				<Rectangle width={randomWidth} height={height}/>
			</MenuItem>
		));
	}

	return (
		<Header>
			<Brand>
				<Rectangle width={'120px'} height={'10px'}/>
			</Brand>
			<Menu>
				<MenuItems>
					{menuItems}
				</MenuItems>
			</Menu>
		</Header>
	);
}
