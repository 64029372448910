import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import TileList from './TileList';

/**
 * Container element
 */
const Container = styled.div`
  font-family: 
    'Open Sans', 
    -apple-system, 
    BlinkMacSystemFont, 
    "Segoe UI", 
    Roboto, 
    "Helvetica Neue", 
    Arial, 
    "Noto Sans", 
    sans-serif, 
    "Apple Color Emoji", 
    "Segoe UI Emoji", 
    "Segoe UI Symbol", 
    "Noto Color Emoji";
  font-size: 1rem;
  background-color: #fff;
`;

/**
 * TarkNews theme element
 *
 * @param header
 * @param footer
 * @param list
 * @param meta
 * @return Element
 * @constructor
 */
export default function TarkNews ({ header, footer, list, meta }) {
	const gridColumnsQty = window.innerWidth >= 1200 ? 4 : window.innerWidth >= 600 ? 2 : 1;

	const SelectedHeader = () => {
		switch (header.name) {
			default:
				return <Header.Default brand={header.brandName} links={header.links}/>;
		}
	};
	const SelectedFooter = () => {
		switch (footer.name) {
			default:
				return <Footer.Default brand={footer.brand} text={footer.text}/>;
		}
	};

	return (
		<Container>
			<Helmet>
				<title>{ header.header_title ? header.header_title : meta.title }</title>
				<meta name="description" content={meta.description}/>
			</Helmet>
			<SelectedHeader/>
			<TileList list={list} gridColumnsQty={gridColumnsQty} />
			<SelectedFooter/>
		</Container>
	);
}

TarkNews.propTypes = {
	header: PropTypes.shape({
		name: PropTypes.string.isRequired,
		brandName: PropTypes.string.isRequired,
		links: PropTypes.arrayOf(PropTypes.shape({
			text: PropTypes.string.isRequired,
			href: PropTypes.string.isRequired
		})).isRequired
	}).isRequired,
	footer: PropTypes.shape({
		name: PropTypes.string.isRequired,
		brand: PropTypes.string.isRequired
	}),
	list: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.string.isRequired,
		data: PropTypes.object.isRequired
	})),
	meta: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	}).isRequired
};
