import React from 'react';
import styled from 'styled-components';
import Rectangle from '../../Rectangle';

/**
 * Block element
 */
const Block = styled.div`
  background: white;
  box-shadow: 0 2px 15px rgba(0,0,0,.09);
  height: 100%;
  width: 100%;
`;

/**
 * DarkHeader element
 */
const Header = styled.header``;

/**
 * Body element
 */
const Body = styled.div`
  padding: .5rem 1rem;
`;

export default function () {
	const lines = [];
	for (let i = 0; i < 7; ++i) {
		lines.push((
			<React.Fragment key={i}>
				<Rectangle
					width={ (Math.floor(Math.random() * (100 - 20)) + 20) + '%'}
					height={'5px'}
				/>
				<br/>
			</React.Fragment>
		));
	}
	return (
		<Block>
			<Header>
				<Rectangle width={'100%'} height={'8rem'}/>
			</Header>
			<Body>
				<Rectangle width={'40%'} height={'15px'}/> <br/>
				<Rectangle width={'70%'} height={'6px'}/>
				<br/>
				{lines}
			</Body>
		</Block>
	);
}
