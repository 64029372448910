import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Container element
 */
const Container = styled.div`
    width: 100%;
    height: 100%;
    background: rgb(249, 249, 249);
`;

Container.propTypes = {
	children: PropTypes.any.isRequired
};

export default Container;
